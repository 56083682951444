import IntlMessages from "@uni/utility/IntlMessages";

const useGetColumns = () => {
  return [
    {
      title: "Mapping table",
      dataIndex: ["map_table", "mapping_table_name"],
      key: "table_A_rows",
      width: 120,
      sorter: true,
    },
    {
      title: "Table A",
      dataIndex: ["map_table", "mapping_table_A"],
      key: "mapping_table_A",
      width: 150,
    },
    {
      title: "Table B",
      dataIndex: ["map_table", "mapping_table_B"],
      key: "mapping_table_B",
      width: 150,
    },
    {
      title: "Mapping Table A rows",
      dataIndex: ["map_table", "mapping_A_rows"],
      key: "mapping_A_rows",
      align: "right",
      width: 150,
    },
    {
      title: "Mapping Table B rows",
      dataIndex: ["map_table", "mapping_B_rows"],
      key: "mapping_B_rows",
      align: "right",
      width: 150,
    },
    {
      title: "Mapping date",
      dataIndex: ["map_table", "mapping_date"],
      align: "right",
      key: "mapping_date",
      width: 80,
    },
  ];
};

export default useGetColumns;
