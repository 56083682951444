import { LeftOutlined } from "@ant-design/icons";
import AppTableContainer from "@uni/core/AppTableContainer";
import AppViewDetail from "@uni/core/AppViewDetail";
import { useLayoutContext } from "@uni/utility/AppContextProvider/LayoutContextProvider";
import IntlMessages from "@uni/utility/IntlMessages";
import { Button, Spin } from "antd";
import useGetMapTableById from "hooks/apis/mapTable/useGetMapTableById";
import { centerItems } from "pages/system/Settings";
import { forwardRef, useState } from "react";
import { useIntl } from "react-intl";
import { Link, useLocation } from "react-router-dom";
import useGetColumns from "./useGetColumns";
import useAppShowHideTableColumn from "hooks/UseAppShowHideTableColumn";

const TABLE_KEY = "MappingDetailTable";

// Extract the part after '/mapping/'
const extractMappingPath = (path) => {
  const mappingIndex = path.indexOf("/mapping/");
  if (mappingIndex !== -1) {
    return path.substring(mappingIndex + "/mapping/".length);
  }
  return "";
};
const ViewMapTableDrawer = () => {
  const { companyManagement } = useLayoutContext();
  const { pathname } = useLocation();
  const mappingColumns = useGetColumns();

  const mappingPath = extractMappingPath(pathname);
  const { mapTableData, isFetching } = useGetMapTableById({
    folder_slug: companyManagement?.company?.detail?.folder?.slug || "",
    company_slug: companyManagement?.company?.detail?.company?.slug || "",
    map_table_name: mappingPath,
  });

  const { filteredTableColumns, tableColumnDisplayConfigurator } =
    useAppShowHideTableColumn({
      tableKey: TABLE_KEY,
      tableColumns: mappingColumns,
    });

  const [columnsState, setColumnsState] = useState(() =>
    mappingColumns?.map((column, i) => ({
      ...column,
      key: `${i}`,
      onHeaderCell: () => ({
        id: `${i}`,
        draggable: column.draggable !== false,
      }),
      onCell: () => ({
        id: `${i}`,
      }),
    }))
  );

  const { messages } = useIntl();

  return (
    <div style={{ padding: "16px 20px 0", background: "#fff" }}>
      <div className="app-view-detail">
        <div className="app-view-detail-header">
          <Button type="text" icon={<LeftOutlined />} className="btn-back">
            <Link to="/mapping" style={{ color: "#000" }}>
              <IntlMessages id="common.backToList" />
            </Link>
          </Button>
        </div>

        <div>
          {isFetching ? (
            <Spin
              spinning
              style={{
                ...centerItems,
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backgroundColor: " rgba(255, 255, 255, 0.5)",
                overflow: "hidden",
                zIndex: 99999999,
                height: "100vh",
              }}
            />
          ) : (
            <div style={{ marginTop: 20 }}>
              {/* <div style={{ background: "#eee", padding: "6px 12px" }}>
                {mapTableData?.results?.[0]?.map_table?.mapping_table_name}
              </div> */}

              <AppTableContainer
                loading={isFetching}
                data={mapTableData?.results || []}
                columnsState={columnsState}
                setColumnsState={setColumnsState}
                columns={filteredTableColumns}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewMapTableDrawer;
